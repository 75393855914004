import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Container, Row, Col, Image } from "react-bootstrap";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import clients from "../../shared/pages/case-studies";
import styles from "./CaseStudies.module.css";
import { ButtonPrimary } from "../Buttons";
import CarouselStatic from "./ImagesCarousel/CarouselStatic";
import { head } from "../../shared/scripts";

const CaseStudies = () => {
  const data = useStaticQuery(graphql`
    {
      file(name: { eq: "case-studies-image" }) {
        name
        publicURL
      }
      allWpPage(
        filter: { template: { templateName: { eq: "Case Studies" } } }
      ) {
        nodes {
          cs {
            caseStudies {
              galeria {
                altText
                title
                id
                sourceUrl
              }
              tag
              tekst
              logo {
                altText
                id
                title
                sourceUrl
              }
            }
          }
          language {
            code
          }
        }
      }
    }
  `);
  const { language } = React.useContext(GlobalStateContext);
  const caseStudies = head(
    data.allWpPage.nodes.filter(
      node => node.language.code === language.toUpperCase()
    )
  ).cs.caseStudies;
  const { title, button, footerSection } = clients[language];

  return (
    <Container className={styles.self}>
      <h1 className="triangle tsmall yellow">{title}</h1>{" "}
      {caseStudies.map((cs, i) => {
        const { galeria, tekst, logo, tag } = cs;
        const sty = { flexDirection: i % 2 ? "row-reverse" : "row" };
        return (
          <section id={tag}>
            <div key={`cs-${logo.id}`}>
              <Row>
                <Col xs={12} className={styles.logo}>
                  <Image
                    width="150"
                    src={logo.sourceUrl}
                    alt={logo.altText}
                    title={logo.title}
                  />
                </Col>
              </Row>
              <Row className={styles.content} style={sty}>
                <Col
                  md={6}
                  className={styles.column}
                  dangerouslySetInnerHTML={{ __html: tekst }}
                />

                <Col md={6} className={styles.column}>
                  <CarouselStatic images={galeria} />
                </Col>
              </Row>
            </div>
          </section>
        );
      })}
      {footerSection ? (
        <Row>
          <Col className={styles.final} md={{ span: 5, offset: 4 }}>
            <h3>{footerSection.title}</h3>
            <p>{footerSection.text}</p>
            <ButtonPrimary
              variant="outline-primary"
              to="contact"
              language={language}
            >
              {footerSection.button}
            </ButtonPrimary>
            <Image className={styles.image} src={data.file.publicURL} />
          </Col>
        </Row>
      ) : (
        <Row className={styles.button}>
          <ButtonPrimary
            variant="outline-primary"
            to="contact"
            language={language}
          >
            {button}
          </ButtonPrimary>
        </Row>
      )}
    </Container>
  );
};

export default CaseStudies;
