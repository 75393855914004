import React, { useContext } from "react";
import styles from "./ButtonPrimary.module.css";
import { Link } from "gatsby";
import { langPrefix } from "../../../shared/scripts";
import { CHANGE_PAGE } from "../../../types";
import { GlobalDispatchContext } from "../../../context/GlobalContextProvider";

const ButtonPrimary = props => {
  const dispatch = useContext(GlobalDispatchContext);

  const onPageChange = page => {
    dispatch({ type: CHANGE_PAGE, payload: page });
  };

  return (
    <Link
      onClick={() => onPageChange(props.to)}
      to={`${langPrefix(props.language)}${props.to}`}
      className={styles.button}
    >
      {props.children}
    </Link>
  );
};

export { ButtonPrimary };
