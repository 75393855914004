import React, { useEffect, useState } from "react";
import { Carousel, Image } from "react-bootstrap";
import styles from "./ImagesCarousel.module.css";

const CarouselStatic = ({ images, language }) => {
  return (
    <div>
      <Carousel indicators={false} controls>
        {images.map(({ sourceUrl, id }) => {
          return (
            <Carousel.Item className={styles.carouselItem} key={id}>
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  backgroundImage: `url(${sourceUrl})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat"
                }}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselStatic;
