export default {
  en: {
    title: "Case studies",
    button: "Order visualizations of your packages",
    footerSection: {
      title: "Your packaging can do more than you think!",
      text: "Test our ideas and let us positively surprise you!",
      button: "ASK FOR A FREE PACKAGE VISUALISATION"
    }
  },
  dk: {
    title: "Case studies",
    button: "MODTAG ET GRAFISK UDKAST AF DIN EMBALLAGE",
    footerSection: {
      title: "Emballage kan mere end du tror!",
      text: "Test vores ideer og lad os give dig en god overraskelse!",
      button: "Modtag et gratis grafisk udkast!"
    }
  },
  es: {
    title: "NUESTROS PROYECTOS",
    button: "SOLICITA VISUALIZACIÓN GRATUITA",
    footerSection: {
      title: "¡Tus envases pueden aportar mucho más de lo que te imaginas!",
      text: "¡Prueba nuestros servicios y dejános sorprenderte!",
      button: "SOLICITA VISUALIZACIÓN GRATUITA"
    }
  },
  de: {
    title: "UNSERE LEISTUNGEN",
    button: "Order visualizations of your packages",
    footerSection: {
      title: "Ihre Verpackungen können viel mehr als Sie denken!",
      text: "Testen Sie unsere Ideen und lassen Sie sich positiv überraschen!",
      button: "BESTELLEN SIE EINE KOSTENLOSE VISUALISIERUNG"
    }
  },
  fr: {
    title: "NOS RÉALISATIONS",
    button: "COMMANDEZ UNE VISUALISATION GRATUITE",
    footerSection: {
      title: "Votre emballage peut faire bien plus que vous ne le pensez !",
      text: "Testez nos idées et laissez-nous vous surprendre positivement !",
      button: "COMMANDEZ UNE VISUALISATION GRATUITE"
    }
  }
};
