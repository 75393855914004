import React from "react";
import styles from "./LinkButton.module.css";
import { Link } from "gatsby";

const LinkButton = props => {
  return (
    <Link to={props.to} className={styles.button}>
      {props.children}
    </Link>
  );
};

export { LinkButton };
